import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';
import SectionMedia from '../SectionMedia';
import styles from './styles.module.css';

class DataSheetLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    const index = props.section.data.length - 1;
    const dataIndex = props.section.data.length - 2;
    const align = props.section.data[index].align !== undefined ? props.section.data[index].align : 'Left';
    const full = props.section.data[index].full === true ? 'Full' : '';

    this.state = {
      section: props.section,
      layout: props.section.data[index].layout,
      align,
      full,
      columnTitles: props.section.data[dataIndex].columnTitles,
      columnWidths: props.section.data[dataIndex].columnWidths,
      columnNum: props.section.data[dataIndex].columnNum,
      rowTitles: props.section.data[dataIndex].rowTitles,
      zebraStripes: props.section.data[dataIndex].zebraStripes,
      dataIndex,
    };

    this.renderHeading = this.renderHeading.bind(this);
    this.renderRow = this.renderRow.bind(this);

  }

  renderHeading(heading, index) {

    const rowWidth = `${this.state.columnWidths[index]}%`;

    const rowStyle = {

      width: rowWidth,

    };

    const rowHeadingElement =
      (
        <thead
          key={`tableHeading_${index}`}
        >
        <tr
          className={styles.tableHeading}
          style={rowStyle}
        >
          {
            heading.map((head, i) => {

              if ((this.state.rowTitles) && (i === 0)) {

                return (
                  <th
                    key={`tableHeading_${i}`}
                    className={`${styles.tableHeadingCell}`}
                  >
                    <div id={`${this.state.section._id}_tableHeading_${index}_${i}`}>
                      <h3 className={`${styles[`Small${this.props.themeData.typography.heading.fontSize}`]}`}>
                        { HTMLParser(`${head.data}`) }
                      </h3>
                    </div>
                  </th>);

              }

              if ((i > 0) && (i < (this.state.columnNum + 1))) {

                return (
                  <th
                    key={`tableHeading_${i}`}
                    className={`${styles.tableHeadingCell}`}
                  >
                    <div id={`${this.state.section._id}_tableHeading_${index}_${i}`}>
                      <h3 className={`${styles[`Small${this.props.themeData.typography.heading.fontSize}`]}`}>
                        { HTMLParser(`${head.data}`) }
                      </h3>
                    </div>
                  </th>
                );

              }

              return null;

            })
          }
        </tr>
        </thead>
      );

    return rowHeadingElement;

  }

  renderRow(rowData, index, mobileLabels) {

    const color = {
      color: '#000',
    };

    const rowElement =
      (
        <tr
          key={`tableRow_${index}`}
          className={this.state.zebraStripes ? styles.tableRowStriped : styles.tableRowWrapper}
        >
          {
            rowData.map((data, i) => {

              const mobileLabelText = mobileLabels && mobileLabels.data[i].data.replace(/<\/?[^>]+(>|$)/g, '');
              const rowWidth = !this.props.matches ? '100%' : `${this.state.columnWidths[i]}%`;
              const rowStyle = { width: rowWidth };

              if ((this.state.rowTitles) && (i === 0)) {

                return (
                  <td
                    key={`tableData_${i}`}
                    style={rowStyle}
                    className={styles.tableRowHeading}
                    valign="top"
                  >
                    <div
                      id={`${this.state.section._id}_tableRowHeading_${index}_${i}`}
                      className={`${styles[`Small${this.props.themeData.typography.heading.fontSize}`]}`}
                      style={color}
                    >
                      { HTMLParser(`${data.data}`) }
                    </div>
                  </td>

                );

              }

              if ((i < (this.state.columnNum + 1)) && (i > 0)) {

                return (
                  <td
                    key={`tableData_${i}`}
                    style={rowStyle}
                    className={styles.cell}
                    data-label={mobileLabelText}
                    valign="top"
                  >
                    <div
                      id={`${this.state.section._id}_Cell_${index}_${i}`}
                      className={`${styles[`Body${this.props.themeData.typography.heading.fontSize}`]}`}
                      style={color}
                    >
                      { HTMLParser(`${data.data}`) }
                    </div>
                  </td>
                );

              }

              return null;

            })
          }
        </tr>
      );

    return rowElement;

  }

  render() {

    const alignStyle = `align${this.state.align}`;

    const color = {
      color: '#000',
    };

    const title2Style = `Title2${this.props.themeData.typography.heading.fontSize}`;
    const bodyStyle = `Body${this.props.themeData.typography.default.fontSize}`;
    const smallStyle = `Small${this.props.themeData.typography.default.fontSize}`;

    const heading = [];
    const rows = [];
    const mobileLabels = this.state.section.data
      .filter((comp, index) => comp.type.startsWith('COMPONENT') && index === 3 && comp.active);

    this.state.section.data.map((comp, index) => {

      if (comp.type.startsWith('COMPONENT') && index === 3 && comp.active) {

        heading.push(this.renderHeading(comp.data, index));

      }

      if (comp.type.startsWith('COMPONENT') && index > 3 && comp.active) {

        rows.push(this.renderRow(comp.data, index, mobileLabels[0]));

      }

      return null;

    });

    const isFull = this.state.layout === 'fullLeft' || this.state.layout === 'fullRight';

    let { imageWrapper } = styles;
    let sectionMediaWrapper = styles.imageContent;
    if (isFull === true) {

      imageWrapper = styles.imageWrapperFull;
      sectionMediaWrapper = 'imageContent4';

    } else if (this.state.section.data[2].data.icon) {

      imageWrapper = styles.imageWrapperIcon;
      sectionMediaWrapper = '';

    }

    let leftWrapper = styles.datasheetLeftWrapper;

    let rightWrapper = this.state.section.data[2].active ? styles.datasheetRightWrapperCenter : '';
    let contentWrapper = '';
    let colLeft;
    let colRight;
    let rowWrapper = '';


    if (this.state.layout === 'left') {

      leftWrapper = styles.datasheetLeftWrapperCenter;
      colLeft = 'col-12 col-md-12';
      colRight = 'col-12 col-md-12';
      rowWrapper = styles.datasheetRowWrapperCenter;
      contentWrapper = styles.contentWrapperCenter;

    } else if (this.state.layout === 'fullLeft') {

      colLeft = 'col-12 col-md-6';
      colRight = 'col-12 col-md-6';
      leftWrapper = styles.datasheetWrapperFullLeft;
      rightWrapper = styles.datasheetWrapperFull;
      // eslint-disable-next-line
      contentWrapper = styles.contentWrapper;

    } else if (this.state.layout === 'fullRight') {

      colLeft = 'col-12 col-md-6';
      colRight = 'col-12 col-md-6';
      leftWrapper = styles.datasheetWrapperFullLeft;
      rightWrapper = styles.datasheetWrapperFull;
      rowWrapper = styles.datasheetRowWrapper;
      // eslint-disable-next-line
      contentWrapper = styles.contentWrapper;

    }

    const leftContent = [];

    const iconStyle = styles.datasheetIcon;
    const centerImageWrapper = 'datasheetImageCenterWrapper';

    if (this.state.section.data[2].active && this.state.layout === 'left') {


      const image = (
        <SectionMedia
          key={`image_${this.state.section._id}`}
          mediaType={this.state.section.data[2].data.icon ? 'ICON' : 'IMAGE'}
          wrapperStyle={sectionMediaWrapper}
          elementStyle={centerImageWrapper}
          elementIndex={2}
          iconStyle={iconStyle}
          src={`${process.env.IMAGES_CDN}/${this.state.section.data[2].data.src}`}
          alt={this.state.section.data[2].data.alt}
          data={this.state.section.data[2].data}
          images={this.props.images}
          pagePathList={this.props.pagePathList}
          articlePathList={this.props.articlePathList}
        />
      );

      leftContent.push(image);

    }

    if (this.state.section.data[0].active) {

      const sectionHeading = (

        <div
          key={`heading_${this.state.section._id}`}
          id={`${this.state.section._id}_Title_0_section`}
          className={`${styles[title2Style]} ${styles[alignStyle]} ${styles.titleMargin}`}
          style={color}
        >
          { HTMLParser(`<h2>${this.state.section.data[0].data}</h2>`) }
        </div>
      );

      leftContent.push(sectionHeading);

    }

    if (this.state.section.data[1].active) {

      const subtitle = (
        <div
          key={`subtitle_${this.state.section._id}`}
          id={`${this.state.section._id}_Paragraph_1_section`}
          className={`${styles.datasheetSubtitle} ${styles[bodyStyle]} ${styles[alignStyle]}`}
        >
          { HTMLParser(`<span>${this.state.section.data[1].data}</span>`) }
        </div>
      );

      leftContent.push(subtitle);

    }

    const table = (
      <React.Fragment
        key={`dataTable_${this.state.section._id}`}
      >
        <table className={styles.dataTable}>
          {(this.state.columnTitles && this.props.matches) ? heading : null}
          <tbody>
            {rows}
          </tbody>
        </table>
      </React.Fragment>
    );

    leftContent.push(table);

    if (this.state.section.data[this.state.dataIndex - 1].active) {

      const footerText = (
        <div
          key={`footerText_${this.state.section._id}`}
          id={`${this.state.section._id}_Paragraph_2_section`}
          className={`${styles[smallStyle]} ${styles[alignStyle]} ${styles.datasheetFooter}`}
        >
          { HTMLParser(`<span>${this.state.section.data[this.state.dataIndex - 1].data}</span>`) }
        </div>

      );

      leftContent.push(footerText);

    }

    return (
      <div
        className={isFull && (this.props.full || !this.props.matches) ? undefined : 'container'}
        style={{ width: '100%' }}
      >
        <div className={`row no-gutters ${rowWrapper}`}>
          <div className={`${colRight} ${rightWrapper}`}>
            {
              this.state.section.data[2].active &&
              this.state.layout !== 'left' &&
              <div className={imageWrapper}>
                <SectionMedia
                  mediaType={this.state.section.data[2].data.icon ? 'ICON' : 'IMAGE'}
                  wrapperStyle={sectionMediaWrapper}
                  elementStyle={isFull ? 'imageFull' : 'datasheetImage'}
                  elementIndex={2}
                  src={this.state.section.data[2].data.src}
                  alt={this.state.section.data[2].data.alt}
                  data={this.state.section.data[2].data}
                  images={this.props.images}
                  pagePathList={this.props.pagePathList}
                  articlePathList={this.props.articlePathList}
                />
              </div>
            }
          </div>
          <div className={`${colLeft} ${leftWrapper}`}>
            <div className={contentWrapper} style={this.props.boxStyle}>
              { leftContent }
            </div>
          </div>
        </div>
      </div>
    );

  }

}

DataSheetLayout.propTypes = {
  mobileView: PropTypes.bool,
  section: PropTypes.shape({}),
  align: PropTypes.string,
  layout: PropTypes.string,
  full: PropTypes.string,
  columnTitles: PropTypes.bool,
  columnWidths: PropTypes.arrayOf(PropTypes.number),
  columnNum: PropTypes.number,
  rowTitles: PropTypes.bool,
  zebraStripes: PropTypes.bool,
  index: PropTypes.number,
  themeData: PropTypes.shape({
    colors: PropTypes.arrayOf(PropTypes.string),
    typography: PropTypes.shape({
      default: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
      heading: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
      navigation: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
    }),
  }),
};

export default CSSModules(DataSheetLayout, styles, { allowMultiple: true });
